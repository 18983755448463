/* eslint-disable */
import React, { useContext } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from 'src/auth/context/firebase/auth-context';
import { ref, set, push, get, child, onValue, update, increment, remove, off } from 'firebase/database';
import { rtdb } from 'src/auth/context/firebase/auth-provider';
import { IoClose } from 'react-icons/io5';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
// utils
import { fToNow } from 'src/utils/format-time';
// components
import Label from 'src/components/label';
import FileThumbnail from 'src/components/file-thumbnail';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, closeDrawer }) {
  const { user } = useContext(AuthContext);
  const { triggerNotification } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { enqueueSnackbar } = useSnackbar();
  // State to prevent markAsRead if dismissNotification is executed
  const [preventMarkAsRead, setPreventMarkAsRead] = useState(false);
  // const renderAvatar = (
  //   <ListItemAvatar>
  //     {notification.avatarUrl ? (
  //       <Avatar src={notification.avatarUrl} sx={{ bgcolor: 'background.neutral' }} />
  //     ) : (
  //       <Stack
  //         alignItems="center"
  //         justifyContent="center"
  //         sx={{
  //           width: 40,
  //           height: 40,
  //           borderRadius: '50%',
  //           bgcolor: 'background.neutral',
  //         }}
  //       >
  //         <Box
  //           component="img"
  //           src={`/assets/icons/notification/${
  //             (notification.type === 'order' && 'ic_order') ||
  //             (notification.type === 'chat' && 'ic_chat') ||
  //             (notification.type === 'mail' && 'ic_mail') ||
  //             (notification.type === 'delivery' && 'ic_delivery')
  //           }.svg`}
  //           sx={{ width: 24, height: 24 }}
  //         />
  //       </Stack>
  //     )}
  //   </ListItemAvatar>
  // );

  const renderText = (
    <ListItemText
      disableTypography
      primary={
        notification.subAdmin ? (
          <Box component="span">
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {notification.displayName ? notification.displayName : notification.subAdmin}:
            </Box>
            {` ${notification.title ? notification.title : notification.placeName}`}
          </Box>
        ) : notification.isAdmin ||
          (notification.date && user.adminUid && !user.subAdmin && !notification.interval) ? (
          <Box component="span">
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              Admin:
            </Box>
            {` ${notification.title ? notification.title : notification.placeName}`}
          </Box>
        ) : notification.date && !user.adminUid || user.subAdmin ? (
          <Box component="span">
            {notification.displayName && (
              <Box component="span" sx={{ fontWeight: 'bold' }}>
                {notification.displayName}:
              </Box>
            )}
            {` ${notification.title ? notification.title : notification.placeName}`}
          </Box>
        ) : (
          reader(notification.title ? notification.title : notification.placeName)
        )
      }
      secondary={
        <Box sx={{ typography: 'caption', color: 'text.disabled' }}>
          <Stack
            direction="row"
            alignItems="center"
            divider={
              <Box
                sx={{ width: 2, height: 2, bgcolor: 'currentColor', mx: 0.5, borderRadius: '50%' }}
              />
            }
          >
            {notification.description}
            {/* {` ${notification.date ? notification.displayName : ''}`} */}
          </Stack>
          <Box mt={1}>
            {new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }).format(new Date(notification.start ? notification.start : notification.date))}
          </Box>
        </Box>
      }
      sx={{ mr: 2 }}
    />
  );

  // Consider notifications to be unread if `isUnRead` is not set.
  const renderUnReadBadge = notification.isUnRead !== false && (
    <Box
      sx={{
        top: 28,
        width: 8,
        height: 8,
        left: 5,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  const markAsRead = async (updateFlagOnly = false) => {
    if (preventMarkAsRead) {
      // Reset the flag for future interactions
      setPreventMarkAsRead(false);
      return;
    }

    if (!navigator.onLine) {
      enqueueSnackbar('No internet connection', { variant: 'error' });
      console.log('User is offline. Action aborted.');
      return;
    }

    let refPath;

    // Determine if the item is a notification, a calendar event, or a recurring notification
    if (!notification.date && !notification.interval) {
      // Construct the path for a notification
      refPath = `calendar/${user.uid}/${notification.id}`;
    } else if (notification.interval) {
      refPath = `recurringReminders/${user.uid}/${notification.markerId}/${notification.id}`;
    } else {
      // Construct the path for a calendar event
      refPath = `notifications/${user.uid}/${notification.userKey}/${notification.markerId}/${notification.noteKey}`;
    }

    // Update the isUnRead flag in the database if the path exists
    if (refPath) {
      const itemRef = ref(rtdb, refPath);
      console.log('PATH', itemRef, refPath);

      try {
        const snapshot = await get(itemRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const isUnRead = data.isUnRead !== undefined ? data.isUnRead : true;

          // Toggle the isUnRead flag
          if (updateFlagOnly) {
            console.log('HEREEEE', updateFlagOnly);
            if (isUnRead) {
              await update(itemRef, { isUnRead: false });
              console.log(`isUnRead updated to false for path:`, refPath);
            }
          } else {
            // Toggle the isUnRead flag
            await update(itemRef, { isUnRead: !isUnRead });
            console.log(`isUnRead updated to ${!isUnRead} for path:`, refPath);
          }
        } else {
          console.log('Path does not exist:', refPath);
        }
      } catch (error) {
        console.error('Error checking or updating isUnRead:', error);
      }
    } else {
      console.log('Unknown item type or missing information');
    }
  };

  // const dismissNotification = (e) => {
  //   // Prevent markAsRead from being executed
  //   e.stopPropagation(); // This stops the event from reaching the ListItemButton click handler

  //   if (!notification.date && !notification.interval) {
  //     console.log('znotraj dismiss');
  //     const eventRef = ref(rtdb, `calendar/${user.uid}/${notification.id}`);
  //     update(eventRef, { dismiss: true }); // Update the database to mark as dismissed
  //   } else if (notification.interval) {
  //     update(ref(rtdb, `recurringReminders/${user.uid}/${notification.markerId}/${notification.id}`), {
  //       isDismissed: true,
  //     }).then(() => {
  //       console.log('isDismissed updated for recurring reminder:', notification.id);
  //     }).catch(error => {
  //       console.error('Error updating date for recurring reminder:', error);
  //     });
  //   } else {
  //     const notificationRef = ref(
  //       rtdb,
  //       `notifications/${user.uid}/${notification.userKey}/${notification.markerId}/${notification.noteKey}`
  //     );
  //     remove(notificationRef) // Delete the notification from the database
  //     .then(() => console.log('Notification removed successfully.'))
  //     .catch(error => console.error('Error removing notification:', error));
  //     // Add dismiss: true to markers
  //     let notificationPath;
  //     if(user.enterprise) {
  //       notificationPath = `markers/${notification.userKey}/${notification.markerId}/notes/${notification.noteKey}`
  //     } else {
  //       notificationPath = `markers/${user.uid}/${notification.markerId}/notes/${notification.noteKey}`
  //     }
  //     const markerRef = ref(
  //       rtdb,
  //       notificationPath
  //       );
  //     console.log('PATH:',notificationPath)
  //     update(markerRef, { dismiss: true }); // Update the database to mark as dismissed
  //   }
  // };

  //NOVA KODA, KI TUDI PREVERI, ČE PATH ŠE VEDNO OBSTAJA V DB, ZATO DA NE UPDATE-A POTI, KI JE ŽE ZBRISANA (NPR. DISMISS: TRUE ZA MARKER, KI NE OBSTAJA VEČ)
  const dismissNotification = async (e) => {
    // Prevent markAsRead from being executed
    e.stopPropagation(); // This stops the event from reaching the ListItemButton click handler

    const checkPathExists = async (path) => {
      const snapshot = await get(ref(rtdb, path));
      return snapshot.exists();
    };

    try {
      if (!notification.date && !notification.interval) {
        console.log('Inside dismiss');
        const eventPath = `calendar/${user.uid}/${notification.id}`;
        const eventExists = await checkPathExists(eventPath);

        if (eventExists) {
          const eventRef = ref(rtdb, eventPath);
          await update(eventRef, { dismiss: true }); // Update the database to mark as dismissed
        } else {
          console.log('Event path does not exist.');
        }
      } else if (notification.interval) {
        const recurringPath = `recurringReminders/${user.uid}/${notification.markerId}/${notification.id}`;
        const recurringExists = await checkPathExists(recurringPath);

        if (recurringExists) {
          await update(ref(rtdb, recurringPath), {
            isDismissed: true,
          });
          console.log('isDismissed updated for recurring reminder:', notification.id);
        } else {
          console.log('Recurring reminder path does not exist.');
        }
      } else {
        const notificationPath = `notifications/${user.uid}/${notification.userKey}/${notification.markerId}/${notification.noteKey}`;
        const notificationExists = await checkPathExists(notificationPath);

        if (notificationExists) {
          let markerPath;
          let notificationCountPath;
          if (user.enterprise || user.subAdmin) {
            markerPath = `markers/${notification.userKey}/${notification.markerId}/notes/${notification.noteKey}`;
            notificationCountPath = `markerNums/${notification.userKey}/${notification.markerId}/notificationCount/${user.uid}`;
          } else {
            markerPath = `markers/${user.uid}/${notification.markerId}/notes/${notification.noteKey}`;
            notificationCountPath = `markerNums/${user.uid}/${notification.markerId}/notificationCount/${user.uid}/`;
          }

          const markerExists = await checkPathExists(markerPath);

          if (markerExists) {
            // Decrement the notification count
            await deleteNotify(notificationPath, notificationCountPath);
            const markerRef = ref(rtdb, markerPath);
            await update(markerRef, { dismiss: true, notify: null }); // Update the database to mark as dismissed in odstarnimo notify flag, da ne bi v Map.js slučajno kdaj, če bi user zbrisal note, ki je že bil dismissed in je že enkrat zmanjšal notification count, še enkrat zmanjšal count, ko bi izbrisal note
            console.log('Dismiss flag added to marker.');
          } else {
            console.log('Marker path does not exist.');
          }
        } else {
          console.log('Notification path does not exist.');
        }
      }
    } catch (error) {
      console.error('Error processing dismissal:', error);
    }
  };

  const deleteNotify = async (notificationPath, notificationCountPath) => {
    try {
        const updates = {};

        // Remove the notification
        updates[notificationPath] = null;

        // Decrement the notification count
        updates[notificationCountPath] = increment(-1);

        // Push the batched updates to the Realtime Database
        await update(ref(rtdb), updates);

        console.log('Notification and count decremented successfully', updates);
    } catch (error) {
        console.error('Failed to remove notification:', notificationPath, error);
    }
  };

  const handleShowOnMap = async (markerId) => {
    closeDrawer(); // Close the drawer
    const currentPath = location.pathname; // Access the current path
    console.log('PATH:', currentPath);
    // Check if the current route is different from /dashboard before setting the item
    if (currentPath !== '/dashboard') {
      sessionStorage.setItem('hasBeenRedirected', 'true');
    }
    if (notification.userKey) {
      console.log('UTKAJ', notification.userKey);
      navigate('/dashboard', {
        state: { notificationMarkerId: markerId, employeeId: notification.userKey },
      });
    } else {
      console.log('NO USER KEY');
      navigate('/dashboard', { state: { notificationMarkerId: markerId } });
    }
    // Create a new event and dispatch it
    console.log('ID:', markerId, notification.userKey);
    if (notification.userKey) {
      console.log('trigger');
      triggerNotification(markerId, notification.userKey);
    } else {
      triggerNotification(markerId, null);
    }
    // const event = new CustomEvent('notificationClick', { detail: { markerId, employeeId: notification.userKey } });
    // window.dispatchEvent(event);

    // Mark the notification as read
    await markAsRead(true);
  };

  const friendAction = (
    <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Accept
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  const projectAction = (
    <Stack alignItems="flex-start">
      <Box
        sx={{
          p: 1.5,
          my: 1.5,
          borderRadius: 1.5,
          color: 'text.secondary',
          bgcolor: 'background.neutral',
        }}
      >
        {reader(
          `<p><strong>@Jaydon Frankie</strong> feedback by asking questions or just leave a note of appreciation.</p>`
        )}
      </Box>

      <Button size="small" variant="contained">
        Reply
      </Button>
    </Stack>
  );

  const fileAction = (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        pl: 1,
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
        bgcolor: 'background.neutral',
      }}
    >
      <FileThumbnail
        file="https://argon-boulevard-399809.oa.r.appspot.com/httpsdesign-suriname-2015.mp3"
        sx={{ width: 40, height: 40 }}
      />

      <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} flexGrow={1} sx={{ minWidth: 0 }}>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }} noWrap>
              design-suriname-2015.mp3
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.disabled' }}
              divider={
                <Box
                  sx={{
                    mx: 0.5,
                    width: 2,
                    height: 2,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                  }}
                />
              }
            >
              <span>2.3 GB</span>
              <span>30 min ago</span>
            </Stack>
          }
        />

        <Button size="small" variant="outlined">
          Download
        </Button>
      </Stack>
    </Stack>
  );

  const tagsAction = (
    <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mt: 1.5 }}>
      <Label variant="outlined" color="info">
        Design
      </Label>
      <Label variant="outlined" color="warning">
        Dashboard
      </Label>
      <Label variant="outlined">Design system</Label>
    </Stack>
  );

  const paymentAction = (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button size="small" variant="contained">
        Pay
      </Button>
      <Button size="small" variant="outlined">
        Decline
      </Button>
    </Stack>
  );

  return (
    <ListItemButton
      onClick={() => markAsRead()} // Mark the notification as read when clicked
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        position: 'relative', // Add this to position the dismiss button
      }}
    >
      {renderUnReadBadge}

      <IconButton
        aria-label="close"
        size="small"
        sx={{ position: 'absolute', top: '5px', right: '15px' }}
        onClick={(e) => dismissNotification(e)}
      >
        <Iconify icon="mingcute:close-line" />
      </IconButton>

      {/* {renderAvatar} */}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {notification.markerId && (
          <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleShowOnMap(notification.markerId);
              }}
            >
              Show on map
            </Button>
          </Stack>
        )}
        {/* {notification.type === 'friend' && friendAction}
        {notification.type === 'project' && projectAction}
        {notification.type === 'file' && fileAction}
        {notification.type === 'tags' && tagsAction}
        {notification.type === 'payment' && paymentAction} */}
      </Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
